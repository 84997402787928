const documentsFilters = [
  {
    type: "text",
    id: "customer_name__text",
    label: "Cliente",
    value: null,
    category: "a",
    operators: [""],
    countries: ["PE", "CL"]
  },
  {
    type: "text",
    id: "customer_tax_id",
    label: "RUT",
    value: null,
    category: "a",
    operators: [],
    countries: ["PE", "CL"]
  },
  {
    type: "text",
    id: "branch_id",
    label: "Tienda y Pos",
    value: null,
    value2: null,
    category: "a",
    isCombined: true,
    dependent: {
      type: "text",
      id: "cpos_id",
      label: "POS"
    },
    operators: [],
    countries: ["PE", "CL"]
  },
  // {
  //   type: "text",
  //   id: "cpos_id",
  //   label: "POS",
  //   value: null,
  //   category: "a",
  //   isCombined: true,
  //   isMain: false,
  //   dependent: "branch_id",
  //   operators: [],
  //   disabled: true,
  //   countries: ["PE", "CL"],
  // },
  {
    type: "date",
    id: "date",
    label: "Fecha",
    value: null,
    value2: null,
    category: "b",
    operator: null,
    operators: ["es igual", "es menor a", "es mayor a", "rango"],
    countries: ["PE", "CL"]
  },
  // {
  //   type: 'date',
  //   id: 'due_date',
  //   label: 'Fecha de vencimiento',
  //   value: null,
  //   value2: null,
  //   category: 'b',
  //   operator: null,
  //   operators: ['es igual', 'es menor a', 'es mayor a', 'rango'],
  //   countries: ['PE', 'CL']
  // },
  {
    type: "select",
    isMultiple: true,
    id: "type",
    value: null,
    label: "Tipo de documento",
    choices: [],
    category: "c",
    countries: ["PE", "CL"]
  },
  {
    type: "numeric",
    id: "series",
    label: "Serie",
    value: null,
    category: "c",
    countries: ["PE"]
  },
  {
    type: "numeric",
    id: "serial",
    label: "Correlativo", // 'Número de folio',
    value: null,
    value2: null,
    category: "c",
    operator: null,
    operators: ["es igual", "es menor a", "es mayor a", "rango"],
    countries: ["PE", "CL"]
  },
  // {
  //   type: 'select',
  //   id: 'collection_method',
  //   label: 'Forma de pago',
  //   valueType: 'number',
  //   value: null,
  //   choices: [
  //     {label: 'Contado', value: 1},
  //     {label: 'Crédito', value: 2},
  //     {label: 'Sin costo', value: 3}
  //   ],
  //   category: 'c',
  //   countries: ['PE', 'CL']
  // },
  // {
  //   type: 'radio',
  //   id: 'credit_note__isnull',
  //   label: 'Tiene nota de crédito',
  //   value: null,
  //   choices: [
  //     {label: 'Si', value: 'False'},
  //     {label: 'No', value: 'True'}
  //   ],
  //   category: 'c',
  //   countries: ['PE', 'CL']
  // },
  {
    type: "numeric",
    id: "amount_total",
    label: "Total",
    value: null,
    value2: null,
    category: "c",
    operator: null,
    operators: ["es igual", "es menor a", "es mayor a", "rango"],
    countries: ["PE", "CL"]
  },
  // {
  //   type: 'select',
  //   id: 'status__code',
  //   label: 'Estado',
  //   value: null,
  //   valueType: 'number',
  //   choices: [],
  //   category: 'd',
  //   countries: ['PE', 'CL']
  // },
  {
    type: "text",
    id: "agency_status_info__text",
    label: "Detalle del estado",
    value: null,
    category: "d",
    operators: [""],
    countries: ["PE", "CL"]
  }
]
export { documentsFilters }
